<template>
    <div v-if="json">
        <b-form ref="productForm" :save-url="editUrl" v-slot="{model, loading}"
                :save-params="{json : JSON.stringify(json)}" :initial-data="data">
            <div class="card bs-4 p-0">
                <h5 class="mb-4 bb-1 p-3">Basic Details</h5>
                <div class="p-3">
                    <div class="row mb-2">
                        <div class="col-md-3">
                            <div class="text-5 h-form-label">Name</div>
                        </div>
                        <div class="col-md-9">
                            <validated-input name="Name" :rules="{required: true}"
                                             v-model="model.name" :disabled="loading"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-3">
                            <div class="text-5 h-form-label">Description</div>
                        </div>
                        <div class="col-md-9">
                            <textarea-input name="Description" v-model="model.description"
                                            :disabled="loading"
                                            :rules="{required: true}"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-3">
                            <div class="text-5 h-form-label">Category</div>
                        </div>
                        <div class="col-md-9">
                            <validated-ajax-vue-select :url="categoryOptions" name="Category"
                                                       :rules="{required: true}"
                                                       v-model="model.category" :disabled="loading"/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-3">
                            <div class="text-5 h-form-label">Brand</div>
                        </div>
                        <div class="col-md-9">
                            <validated-ajax-vue-select :url="brandOptions" name="Brand"
                                                       :rules="{required: true}"
                                                       v-model="model.brand" :disabled="loading"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4 bs-4 p-0">
                <div class="fl-te-c mb-3 bb-1 pl-3 pr-3 pt-3">
                    <h5 class="mb-4">Key Features</h5>
                    <btn class="btn-basic-b" color="primary" @click.prevent="showKeyFeature"
                         icon="fa fa-plus" size="xs" text="Add"/>
                </div>
                <draggable v-if="json" :list="json.keyFeatures" @change="log" handle=".handle1">
                    <div class="row mb-2" v-for="(feature, index) in json.keyFeatures" v-bind:key="index">
                        <div class="col-md-1 text-center">
                            <div class="text-5 h-form-label">{{ index + 1 }}.</div>
                        </div>
                        <div class="col-md-10">
                            <textarea-input name="Key Features" v-model="json.keyFeatures[index]"
                                            :disabled="loading"
                                            :rules="{required: false}"/>
                        </div>
                        <div class="col-md-1">
                            <btn class="btn-basic-b handle1" color="primary" icon="fa fa-sort" size="xs"
                                 text=""/>
                            <btn class="btn-basic-b ml-2" color="primary" @click.prevent="deleteKeyFeature(index)"
                                 icon="fa fa-trash" size="xs" text=""/>
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="card mt-4 bs-4 p-0" v-for="record in  details.sections" v-bind:key="record.name">
                <h5 class="mb-4 bb-1 p-3">{{ record.name }}</h5>
                <div class="row mb-2 p-3" v-for="(i, key) in record.attributes" v-bind:key="key">
                    <div class="col-md-3">
                        <div class="text-5 h-form-label">{{ i.name }}</div>
                    </div>
                    <div class="col-md-9">
                        <template>
                            <!--         Text box           -->
                            <div v-if="i.type === 'text'" v-bind:key="key">
                                <validated-input :name="i.name" v-model="json.attributes[i.id]"
                                                 :rules="{required: true}"
                                                 placeholder="" :disabled="loading"></validated-input>
                            </div>
                            <!--         Color Picker           -->
                            <div v-if="i.type === 'color'" v-bind:key="key">
                                <validated-input :name="i.name" v-model="json.attributes[i.id]" type="color"
                                                 :rules="{required: true}"
                                                 placeholder="" :disabled="loading"/>
                            </div>
                            <!--         Date Picker           -->
                            <div v-if="i.type === 'date'" v-bind:key="key">
                                <validated-date-picker class="mb-4" :name="i.name"
                                                       v-model="json.attributes[i.id]"
                                                       :rules="{required: true}"
                                                       prepend-icon="ni ni-calendar-grid-58"
                                                       :disabled="loading"/>
                            </div>
                            <!--         Number           -->
                            <div v-if="i.type === 'number'" v-bind:key="key">
                                <validated-input :name="i.name" v-model="json.attributes[i.id]"
                                                 :rules="{required: true}"
                                                 type="number" :min="1"
                                                 placeholder="" :disabled="loading"></validated-input>
                            </div>
                            <!--         Vue-Select           -->
                            <div v-if="i.type === 'vue-select'" v-bind:key="key">
                                <validated-vue-select :name="i.name" v-model="json.attributes[i.id]"
                                                      :options-u-r-l="i.options"
                                                      :rules="{required: true}">
                                </validated-vue-select>
                            </div>
                            <!--         File Upload           -->
                            <div v-if="i.type === 'file-input'" v-bind:key="key">
                                <file-input class="mb-4" :name="i.name" v-model="json.attributes[i.id]"
                                            :rules="{required: true}"
                                            :disabled="loading"></file-input>
                            </div>
                            <!--         Text Area           -->
                            <div v-if="i.type === 'textarea'" v-bind:key="key">
                                <textarea-input :name="i.name" v-model="json.attributes[i.id]"
                                                :rules="{required: true}"/>
                            </div>
                            <!--         Check box           -->
                            <div v-if="i.type === 'checkbox'" v-bind:key="key">
                                <checkbox-input :name="i.name"
                                                v-model="json.attributes[i.id]"></checkbox-input>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </b-form>
        <modal no-close-on-backdrop title="Key Feature" ref="keyFeatureAddModal" width="30r">
            <s-form @submit="addKeyFeature" ref="keyFeatureForm">
                <textarea-input name="Key Feature" :rules="{required:true}"
                                v-model="feature"/>
                <save-btn type="submit" icon="" size="xs" color="primary" text="Save"/>
            </s-form>
        </modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import urls from '../../../../data/urls';

export default {
    name  : 'AttributeFamily',
    props : {
        details : { type : Object },
        json    : { type : Object }
    },
    components : {
        draggable
    },
    data () {
        return {
            loading         : false,
            section_name    : '',
            feature         : '',
            editUrl         : urls.catalogueAdmin.catalogue.edit,
            categoryOptions : urls.catalogueAdmin.catalogue.categoryOptions,
            brandOptions    : urls.catalogueAdmin.catalogue.brandOptions,
            data            : {
                sku              : '',
                name             : '',
                description      : '',
                attribute_family : '',
                category         : '',
                brand            : '',
                id               : ''
            },
            attribute_family : '',
            confirmOptions   : [
                { value : 0, label : 'Yes' },
                { value : 1, label : 'No' }
            ]
        };
    },
    watch : {
        json : {
            handler () {
                this.json = JSON.stringify(this.json);
                // this.setData();
            }
        }
    },
    beforeMount () {
        this.setData();
    },
    methods : {
        setData () {
            this.data.sku = this.json.sku;
            this.data.name = this.json.name;
            this.data.description = this.json.description;
            this.data.category = this.json.category;
            this.data.brand = this.json.brand;
            this.data.attribute_family = this.json.family_id;
            this.data.id = this.json._id;
        },
        SaveFormData () {
            this.$refs.productForm.onSubmit();
        },
        showKeyFeature () {
            this.feature = '';
            this.$refs.keyFeatureAddModal.show();
        },
        addKeyFeature () {
            this.json.keyFeatures.push(this.feature);
            this.feature = '';
            this.$refs.keyFeatureAddModal.close();
        },
        deleteKeyFeature (index) {
            this.json.keyFeatures.splice(index, 1);
        },
        log : function (evt) {
            window.console.log(evt);
        }
    }
};
</script>
