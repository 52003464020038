<template>
    <div class="card bs-5 p-0">
        <h5 class="p-3" style="height: 45px;">Uploads</h5>
        <loading-animation v-if="DataLoading"></loading-animation>
        <div v-else class="mb-3">
            <div class="bgc-tertiary-dark p-3 h6" style="height: 45px; color: #6c757d;">Photos</div>
            <div class="row p-4">
                <product-images class="card col-md-4 mt-0"
                                v-for="record in data"
                                :key="record.id" :product="record.image" @click="setDelete(record)">
                </product-images>

                <div class="card col-md-4 mt-0">
                    <div style="min-height: 130px; background-position: center center; background-size:cover;"
                         class="round-2 br-1 bs-1 fl-eq-c p-4" @click="$refs.addModal.show()">
                        <img src="https://imagga.com/static/images/upload.svg">
                    </div>
                </div>
            </div>
<!--            <drop-zone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></drop-zone>-->
        </div>
        <modal no-close-on-backdrop title="Upload Image" ref="addModal" width="30r">
            <b-form v-if="!loading" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                    :save-params="{product: product}">
                <validated-file-input placeholder="Image" v-model="model.image" :disabled="loading"/>
                <btn size="xs" text="Upload" :loading="loading" loading-text="Uploading..."></btn>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Image <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import ProductImages from '@/views/CatalogueAdmin/Catalog/components/ProductImages';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name       : 'ListingComponent',
    props      : { product : { type : String } },
    components : { ProductImages },
    data () {
        return {
            // dropzoneOptions : {
            //     url             : 'https://httpbin.org/post',
            //     thumbnailWidth  : 100,
            //     thumbnailHeight : 100,
            //     maxFilesize     : 0.5,
            //     imageZoneNames  : [],
            //     headers         : { 'My-Awesome-Header' : 'header value' }
            // },
            DataLoading   : '',
            data          : '',
            addUrl        : urls.catalogueAdmin.catalogue.addProductImage,
            deleteUrl     : urls.catalogueAdmin.catalogue.deleteProductImage,
            familyOptions : urls.catalogueAdmin.catalogue.familyOptions,
            loading       : false,
            deletingItem  : {
                id      : '',
                image   : '',
                product : ''
            },
            editingItem : null
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.catalogueAdmin.catalogue.listProductImage, { product : that.product });
            const json = response.data;
            that.data = json.data;
            that.DataLoading = false;
        },
        formSuccess (response) {
            this.data = response.data.images.data;
            const refs = this.$refs;
            refs.addModal.close();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.deletingItem.image = item.image;
            this.deletingItem.product = this.product;
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.data = response.data.images.data;
            this.deletingItem = null;
            this.$refs.deleteModal.close();
        }
    }
}
;
</script>

<style>
.bgc-tertiary-dark {
    background-color: #eeeeee;
}

</style>
