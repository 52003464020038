<template>
    <loading-animation class="p-4" v-if="DataLoading"></loading-animation>
    <div v-else>
        <div class="fl-te-t mb-3">
            <h4 v-if="details">SKU # {{ details.sku }}</h4>
            <div class="btn-group">
                <btn size="sm" text="Back" @click="$router.push('/catalogue/')"></btn>
                <btn size="sm" text="Save" @click="onSaveClicked" :loading="loading" loading-text="Saving..."></btn>
            </div>
        </div>
        <div class="fl-te-t">
            <div class="w-30p">
                <product-attachments-component :product="id"></product-attachments-component>
            </div>
            <div class="mt-0" style="width: 69%">
                <attribute-family ref="attributeFam" :details="details" :json="productFinal"></attribute-family>
            </div>
        </div>
    </div>
</template>

<script>
import AttributeFamily from '@/views/CatalogueAdmin/Catalog/components/AttributeFamily';
import ProductAttachmentsComponent from '@/views/CatalogueAdmin/Catalog/components/ProductAttachmentsComponent';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name       : 'Catalog',
    components : { AttributeFamily, ProductAttachmentsComponent },
    data () {
        return {
            DataLoading  : false,
            loading      : false,
            id           : this.$route.params.id,
            details      : null,
            productFinal : null
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async onSaveClicked () {
            this.$refs.attributeFam.SaveFormData();
            // const that = this;
            // that.loading = true;
            // const response = await axios.form(urls.catalogueAdmin.catalogue.saveProductDetails, {
            //     product : that.id,
            //     json    : JSON.stringify(that.data)
            // });
            // const json = response.data;
            // if (json.error === false) {
            //     that.$notify('Saved Successfully', 'Success', {
            //         type : 'success'
            //     });
            //     that.loading = false;
            // } else {
            //     that.$notify('Unable to save details..Please try again later!', 'Error', {
            //         type : 'danger'
            //     });
            //     that.loading = false;
            // }
        },
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.catalogueAdmin.catalogue.details, { product : that.id });
            const json = response.data;
            if (json) {
                that.details = json.data2;
                that.productFinal = json.data1;
                that.DataLoading = false;
            } else {
                that.$notify('File not found', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
        }
    }
};
</script>

<style scoped>
</style>
